<template>
  <div>
    <div class="container">
      <img src="@/assets/img/mock/JobPlanning/banner1.png" alt="" />
      <img src="@/assets/img/mock/JobPlanning/banner2.png" alt="" />
      <img src="@/assets/img/mock/JobPlanning/banner3.png" alt="" />
      <img src="@/assets/img/mock/JobPlanning/banner4.png" alt="" />
      <img src="@/assets/img/mock/JobPlanning/banner5.png" alt="" />
      <img src="@/assets/img/mock/JobPlanning/banner6.png" alt="" />
    </div>
	<div class="prices">
	  <span class="usdPrice">${{ classInfo.usdAmount }}/</span>
	  <span class="rmbPrice">￥{{ classInfo.amount }} </span>
	  <div class="buy" @click="chooseCombo(classInfo.details[0])">立即购买</div>
	</div>
  </div>
</template>

<script>
import mockMix from "@/utils/mockMix";
import { setStorageForArray } from "@/utils/index";
export default {
  name: "JobPlanning",
  data() {
    return {
		provideDialogVisible:false
	};
  },
  mounted() {},
  mixins: [mockMix],
  methods: {
	  chooseCombo(item) {
		setStorageForArray("order", [item]);
		this.$router.push("/pay/confirm");
	  },
  },
};
</script>
<style scoped lang="scss">
	@import url("../../assets/css/course.scss");
.container {
	padding-top: 54px;
  img {
    width: 100%;
    vertical-align: top;
  }
}

.provideBtn {
  width: 80%;
  margin: 0 10%;
}
.provideContainer .el-button {
  margin-bottom: 24px;
  &:hover {
    background: #0a7aff;
    color: #fff;
  }
}
</style>